<template>
  <v-col class="d-flex flex-column justify-space-between fill-height">
    <v-container class="d-flex flex-column justify-start align-center">
      <LeaderboardTable readonly :divisions="divisions" />
    </v-container>
  </v-col>
</template>

<script>
import LeaderboardTable from '../../components/LeaderboardTable.vue'
import LeaderboardApi from '@/api/leaderboard.api'

export default {
  name: 'MonitorLeaderboard',
  components: {
    LeaderboardTable
  },
  data () {
    return {
      divisions: []
    }
  },
  created () {
    setInterval(async () => {
      const response = await LeaderboardApi.getStatistic()
      if (!response.error) {
        this.divisions = response.data
      }
    }, 1000)
  }
}
</script>

<style scoped>
.score-header {
  font-size: 24px;
}
</style>
